<template>
    <div class="user_page">
        <div class="top_change">
        <div class="l_where">
            系统设置<span>/</span>微信小程序
        </div>
        <div class="middle_width flex-b">
            <div class="nav_name" v-if="this.$store.state.identity!=3" :class="activeNav === '1' ? 'active' : ''" @click="changeTable('1')">小程序配置</div>
            <div class="nav_name" v-if="this.$store.state.identity!=3 && !isIndependentDeployment" :class="activeNav === '2' ? 'active' : ''" @click="changeTable('2')">支付配置</div>
            <div class="nav_name" v-if="this.$store.state.identity!=3 && !isIndependentDeployment" :class="activeNav === '3' ? 'active' : ''" @click="changeTable('3')">小程序更新</div>
            <div class="nav_name" v-if="this.$store.state.identity==3 && !isIndependentDeployment" :class="activeNav === '4' ? 'active' : ''" @click="changeTable('4')">小程序二维码</div>
        </div>
        <!-- <div class="middle_width flex-b">
            <div v-for="(item,index) in ($store.state.identity!=3 ? ['1','2','3'] : ['4'])" :key="index" class="nav_name" >
                <div class="nav_name" v-if="$store.state.identity!=3" :class="activeNav === item ? 'active' : ''"  @click="changeTable(item)" >
                {{ item==1 ?'小程序配置': item == 2 ?'支付配置': item == 3 ?'小程序更新': '小程序二维码'   }}
                </div>
            </div>
        </div> -->
        <div class="r_back" @click="goBack">
            <i class="iconfont icon-fanhui"></i>
        </div>
        </div>
        <div class="card_bg" v-if="activeNav === '4'">
            <div class="noapp">
                <div style="margin-right:32px">
                    小程序二维码：
                </div>
                <div class="noapp-ewm">
                    <div style="width: 240px;height: 240px;">
                        <img v-if="EWMing" :src="EWMing"/>
                    </div>
                    <Button style="margin-top:24px;" type="primary" @click="get_applet_qrurl">刷新</Button>  
                </div>
            </div>
        </div>
        <div class="card_bg" v-if="activeNav === '1'">
            <div class="input_nav flex">
                <div class="label">appid：</div>
                <div class="val">
                <Input v-model="formdata.appid"  placeholder="请输入appid"/>
                </div>
            </div>
            <div class="input_nav flex">
                <div class="label">secret：</div>
                <div class="val">
                <Input v-model="formdata.appsecret"  placeholder="请输入secret"/>
                </div>
            </div>
            <div class="xuxian"></div>
            <div class="s-b" >
                <Button type="primary" @click="goone">确定</Button>
            </div>
        </div>
        <div class="card_bg" v-if="activeNav === '2'">

            <div class="input_nav flex">
                    <div class="label long">付款方式：</div>
                    <div>
                        <RadioGroup  v-model="paylist.pay_type">
                            <Radio label="1">微信支付</Radio>
                            <Radio label="2">付呗支付</Radio>
                        </RadioGroup>
                    </div>
                </div>

            <div class="input_nav flex" v-if="paylist.pay_type == 1">
                <div class="label long">微信支付商户号：</div>
                <div class="val">
                    <Input v-model="paylist.mchid"  placeholder="请输入微信支付商户号"/>
                </div>
            </div>
            <div class="input_nav flex" v-if="paylist.pay_type == 1">
                <div class="label long">微信支付商户秘钥：</div>
                <div class="val">
                    <Input v-model="paylist.key"  placeholder="请输入微信支付商户秘钥"/>
                </div>
            </div>
            <div class="input_nav flex" v-if="paylist.pay_type == 2">
                <div class="label long"> 付呗商户号：</div>
                <div class="val">
                    <Input v-model="paylist.fubei_merchant_id"  placeholder="请输入付呗商户号"/>
                </div>
            </div>
            <div class="input_nav flex" v-if="paylist.pay_type == 2">
                <div class="label long">付呗商户门店号：</div>
                <div class="val">
                    <Input v-model="paylist.fubei_store_id"  placeholder="请输入付呗商户门店号"/>
                </div>
            </div>
            <div class="xuxian"></div>
            <div class="s-b">
                <Button type="primary" @click="payBtn">确定</Button>
            </div>
        </div>
        <div v-if="activeNav === '3'">
            <div v-if='!showView'>
                <div class="top-top" >
                    <div class="top_number">
                        <div class="sss">
                            <div class="wrap-item">
                                <div class="num">{{ now_banben }}</div>
                                <div class="word">当前版本</div>
                                <div class="btn-s"></div>
                            </div>
                        </div>
                        <div class="sss">
                            <div class="wrap-item">
                                <div class="num">{{ new_banben || '暂无' }}</div>
                                <div class="word">最新版本</div>
                                <div class="btn-s">
                                <!-- <div class="btn flex-c" v-if="releaseApplet === 1" @click="uploadApplets">更新</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-right">
                        <span @click="go_record ">发布记录</span>
                        <div @click="showUpdatas = true" class="top-rigth-cof">配置信息</div>  
                        <!-- 示例：-2 代码未提交 -1未提交审核 0 审核成功 1审核被拒绝 2 审核中 3 已撤回 4 审核延后 -->
                        <div v-if="ApproveStatus.approve_status == '-3'">暂无信息~</div>
                        <div v-else>
                            <Button v-if='ApproveStatus.approve_status == -2 ' @click="upload_wxcode">上传代码</Button> 
                            <div v-if='ApproveStatus.approve_status == -1' class="in-examine"><div class="fail-examine">未提交</div><Button @click="upload_approve">提交审核</Button></div>
                            <div v-if='ApproveStatus.approve_status == 0' class="in-examine"><div class="fail-examine">审核成功</div><Button @click="releax_code">发布</Button></div>        
                            <div v-if='ApproveStatus.approve_status == 1'><div class="fail-examine"  @click="realfail" style="cursor: pointer;"><Icon type="ios-help-circle-outline" />审核失败</div><Button @click="upload_wxcode">上传代码</Button></div> 
                            <div v-if='ApproveStatus.approve_status == 2' class="in-examine" >审核中</div>     
                            <div v-if='ApproveStatus.approve_status == 3'><div class="fail-examine">已撤回</div><Button @click="upload_approve">提交审核</Button></div> 
                            <div v-if='ApproveStatus.approve_status == 4' class="in-examine">审核延后</div>
                        </div>                  
                    </div>
                </div>
                <div class="history">
                    <div class="title"><i class="iconfont icon-shijian"></i>官方版本维护记录</div>
                    <div class="card_wrap" v-for="(item,index) in historyList" :key="index">
                        <div>{{ item.user_desc }}</div>
                        <div>版本号:{{ item.user_version }}</div>
                        <div>{{ item.create_time }}</div>
                    </div>
                </div>
            </div> 
            <div v-else>  
                <div class="content_box"  style="align-items: center;justify-content: center;display: flex;overflow: hidden">
                    <div  v-show="loading_status1" >
                        <img src="@/assets/setimg/20220720113958.gif" height="100" width="100"/>
                        <div style="text-align: center">加载中</div>
                    </div>
                    <iframe v-show="!loading_status1" id="wx_iframe" :src="auth_url" style="width:100%;height:700px;border:0;" scrolling="no"/>
                </div>
            </div>
        </div>
        <Modal
            :title="applet_name"
            v-model="show_applets"
            :mask-closable="false" style="text-align: center" footer-hide>
            <img :src="applets_login" style="width:300px;height:300px"/>
            <div class="login-must" v-if="showMust">请尽快扫码登录</div>
        </Modal>
        <Modal
            title="发布记录"
            v-model="show_record"
            width="800"
            :mask-closable="false" style="text-align: center" footer-hide>
                <Table :columns="orderColumns"    :data="orderListArr">
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="status">
                        <div v-if="row.status == -1">未提交</div>
                        <div v-if="row.status == 0">审核成功</div>
                        <div v-if="row.status == 1">审核被拒绝</div>
                        <div v-if="row.status == 2">审核中</div>
                        <div v-if="row.status == 3">已撤回</div>
                        <div v-if="row.status == 4">审核延后</div>
                    </template>
                    <template slot-scope="{ row }" slot="is_release">
                        <div v-if="row.is_release == 1">已发布</div>
                        <div v-if="row.is_release == 0">未发布</div>
                    </template>
                </Table>
                <Page
                    :total="tablea.total"
                    :current="tablea.current"
                    @on-change="changeTablea"
                    :page-size="tablea.limit"
                    style="text-align: right;margin-top: 16px"
                    show-elevator show-total />
        </Modal>
        <Modal
          id="update-history-list"
          title="信息"
          v-model="showUpdatas"
          :mask-closable="false"
          width="600"          
          class-name="vertical-center-modal">
            <div class="modal-heigh">
                <Form ref="forminfo"  :model="forminfo" :label-width="170" style="width: 500px;margin-bottom:1px;overflow:auto;">                
                    <FormItem label="小程序名称：" prop="name" >
                        <Input v-model="forminfo.name" type="text" placeholder="请输入小程序名称"></Input>
                    </FormItem>
                    <FormItem label="appid：" prop="appid">
                        <Input disabled v-model="forminfo.appid" type="text" placeholder="请输入apiid"></Input>
                    </FormItem>
                    <FormItem label="手机号：" prop="phone">
                        <Input v-model="forminfo.phone" type="text" placeholder="请输入手机号"></Input>
                    </FormItem>
                    <!-- class="contshow" -->
                    <FormItem label="域名：" prop="url"  >
                        <Input v-model="forminfo.url" type="text" placeholder="请输入域名"></Input>
                    </FormItem>

                    <FormItem label="request合法域名：" prop="request" class="contshow" >
                        <Input v-model="forminfo.request" type="textarea" style="width:280px" placeholder="以https://开头。可填写多个域名,域名间请用;分割"></Input>
                        <img @click="update_domainconfig" src="@/assets/setimg/gengxinlan.png" height="22" class="genxin-img"  width="25"/>
                    </FormItem>
                    <FormItem label="socket合法域名：" prop="wss" class="contshow" >
                        <Input v-model="forminfo.wss" type="textarea" placeholder="以ws//秩可填写多个域名,域名间请用;分割"></Input>
                    </FormItem>
                    <FormItem label="uploadFile合法域名：" prop="upload" class="contshow" >
                        <Input v-model="forminfo.upload" type="textarea" placeholder="以https://刑。可填写多个域名,域名间请用;分割"></Input>
                    </FormItem>
                    <FormItem label="downloadFile合法域名：" prop="download" class="contshow" >
                        <Input v-model="forminfo.download"  type="textarea"  placeholder="以https://刑。可填写多个域名,域名间请用;分割"></Input>
                    </FormItem>  
                    <FormItem label="udp合法域名：" prop="udp" class="contshow" >
                        <Input v-model="forminfo.udp" type="textarea" placeholder="以udp://开头。可填写多个域名,域名间请用;分割"></Input>
                    </FormItem>
                    <FormItem label="tcp合法域名：" prop="tcp" class="contshow" >
                        <Input v-model="forminfo.tcp" type="textarea" placeholder="以tcp://开头。可填写多个域名,域名间请用;分割"></Input>
                    </FormItem>
                    <FormItem label="分类一：" >
                        <Select @on-change="cateChange" v-model="forminfo.first_cate" style="width:200px">
                            <Option v-for="item in forminfo.cate1" :value="item.id" :key="item.id">{{ item.class }}</Option>
                        </Select>
                        <Button @click="get_reloadwxcate" type="primary" ghost style="margin-left:10px">刷新</Button >
                    </FormItem>
                    <FormItem label="分类二：" prop="second_cate" style="margin-bottom:100px" >
                        <Select v-model="forminfo.second_cate" style="width:200px">
                                <Option v-for="item in forminfo.cate2" :value="item.id" :key="item.id">{{ item.class }}</Option>
                            </Select>
                    </FormItem>  
                </Form>            
            </div>
            <div slot="footer">
                <div class="model_bottom_box">
                    <div>
                        <Button v-if=" forminfo.phone && forminfo.appid && formdata.is_first == 1" @click="privacyBtn" type="primary" ghost style="margin-left:10px">更新隐私协议</Button >
                    </div>
                    <div>
                        <Button @click="showUpdatas = false" class="btn">取消</Button>
                        <Button type="primary" @click="handleMessage('forminfo')">确定</Button>                    
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="reasonModal"
            title="失败原因"
            @on-cancel="reasonModal = false">
                <div>
                    {{ApproveStatus.reason?ApproveStatus.reason:'暂无信息~'}}
                </div>
        </Modal>
        <Modal
            v-model="showPrivate"
            title="现网隐私协议"
            width="400" 
            @on-ok="upload_private"
            @on-cancel="showPrivate = false">
                <div style="margin-left:24px">
                    <RadioGroup v-model="privacyVal">
                        <Radio label="2" >尚无现网隐私协议</Radio>
                        <Radio label="1" style="margin-left:8px">已有现网隐私协议</Radio>
                    </RadioGroup>
                </div>
        </Modal>

        <Modal
            v-model="showFitstPrivate"
            title="现网隐私协议"
            width="400" 
            :closable="false"
            :mask-closable="false"
            >   
                <div style="margin-left:24px">
                    <RadioGroup v-model="privacyVal">
                        <Radio label="2" >尚无现网隐私协议</Radio>
                        <Radio label="1" style="margin-left:8px">已有现网隐私协议</Radio>
                    </RadioGroup>
                </div>
                <div class="fenbu" slot="footer">
                    <Button @click="fitstUploadPrivate" type="primary"  class="">确认</Button>
                </div>
        </Modal>
    </div>
</template>

<script>
import config from '@/config/index'
import { save , updateRecord , savepay , applets , paylist , saveQrcode , get_applet_qrurl } from '../../../api/interFace'
import { appletsCheck ,appletsLogin } from '../../../api/appletsupload'
import { get_authstatus,wxBindAuth,get_wxconf,set_wxconf,update_domainconfig,upload_wxcode,upload_approve,releax_code,get_reloadwxcate,upload_private,get_wxapprovestatus,approve_record,get_wxversionlist } from '@/api/center.js'
var that;
export default {
name: "user",
components:{},
data(){
    return{
        showView: true,
        showFitstPrivate: false, //是否第一次提交现网隐私协议
        loading_status1: false,
        showUpdatas: false,   
        show_record: false,     
        showPrivate: false,//隐私协议
        privacyVal: '2',//隐私协议    尚无现网隐私协议：2     已有现网隐私协议：1
        orderColumns: [
            {
                title: '#',
                slot: 'index',
                width:60,
                align: 'center'
            },
            {
                title: '更新时间',
                key: 'created_at',
                align: 'center'
            },
            {
                title: '版本号',
                key: 'version',
                align: 'center'
            },
            {
                title: '审核状态',
                slot: 'status',
                align: 'center'
            },
            {
                title: '是否发布',
                key: 'status',
                slot: 'is_release',
                align: 'center'
            },
            {
                title: '拒绝原因',
                key: 'reason',
                align: 'center'
            }
        ],
        orderListArr: [],
        activeNav: '1',
        EWMing: '',
        auth_url: '',
        inputVal: '',
        applet_name: '请扫码登录小程序',
        show_applets: false,
        reasonModal: false,
        showMust: false,
        applets_login: "",
        forminfo: {
            appid: '',  //示例：wx06e9f03fc8812e49 appid
            url: '',    //示例：yunqian.hulianzaowu.com 域名
            name: '',      //示例：互联云签 小程序名称s    
            request: "", //示例： request合法域名
            wss: '', //示例： socket合法域名
            upload: '', //示例：uploadfile 合法域名
            download: '', //示例： downloafile 合法域名
            udp: '', //示例： udp 合法域名
            tcp : '' , //示例：tcp 合法域名
            first_cate : '' , //示例：0 分类1id
            second_cate : '' , // 示例：0 分类2 id
            cate1 : [] , // 数据列表 分类1 列表
            cate2 : [] , //数据列表 分类2 列表
            phone : '' , //示例： 手机号码
            first_class: '',//第一个分类name
            second_class: '',//第二个分类name
            first_id: '', //第一个分类ID
            second_id: '', //第二个分类ID
        },
        tablea:{
            page: 1,
            total: 0,
            limit: 5,
        },
        formdata:{
            url: '',
            appid: '',
            appsecret: '',
            is_formal: 1,// 是否为正式服       、、0 测试服    1正式服
            is_first: 1, //  0 第一提交代码   1 否
            name: '',
            desc: '',
            key: '',
            uploadBtn: false
        },
        paylist:{
            appid: '',
            appsecret: '',
            key: '',
            pay_type:'1',
            mchid: '',
            fubei_merchant_id:'',
            fubei_store_id:''
        },
        qrcode: '',
        currentVersion: '',
        latestVersion: '',
        historyList: [],
        new_banben: '',        
        now_banben: '',
        timer: "",
        unId: '',
        releaseApplet: '',
        isIndependentDeployment: config.isIndependentDeployment,//是否为独立部署
        ApproveStatus: {
            approve_status: '-3',
            reason: '',
        },
        approve_status: "",
        cate2: [],
    }
},
created() {   
    that = this;
    console.log('isIndependentDeployment',this.isIndependentDeployment)
    let str=this.$store.state.identity
    if(str == 3){
        this.changeTable('4')
        this.get_applet_qrurl()
        return
    }
    this.getlist()
    this.getpaylist()    
    this.unId = this.userInfo.uniacid_id     
},
computed: {
    userInfo(){
        return this.$store.state.userInfo
    },
},
destroyed() {
    clearInterval(that.timer)
},
methods:{
    changeTable(type){
        this.activeNav = type
        if ( type == '3') {
            this.Refresh_Status()
            this.get_authstatus()
        }        
    },
    //获取无小程序绑定码  identity == 3
    get_applet_qrurl(){        
        get_applet_qrurl().then(res=>{
            this.EWMing = ''
            setTimeout(() => {
                this.EWMing = config.apiUrl+'/' + res.data.qr_code_url
            }, 100);            
        }).catch( err=>{
            this.$Message.error(err.msg)
        })   
    },
    realfail(){
        this.ApproveStatus.reason = ''
        this.reasonModal = true
        this.get_wxapprovestatus()
    },
    Refresh_Status(){
        this.$store.commit('changePollingSpin', false)
        let that = this
        window.addEventListener('message', function(event) {
            if (event.data.code==200) {
                that.get_authstatus()
            }
        });
    },
    goBack(){
        this.$emit('goBack')
    },
    //分类切换 分类二重置
    cateChange(){
        if (this.forminfo.first_cate) {
            let data = []
            this.cate2.forEach(item => {
                if (item.pid == this.forminfo.first_cate) {
                    data.push(item)
                }
            });
            this.forminfo.cate2 = data
            this.forminfo.second_cate = ''
        }
    },    
    //更新小程序域名配置项
    update_domainconfig(){
        update_domainconfig().then(res=>{
            this.$Message.success(res.msg)
            this.getWxConf() //获取配置信息
        }).catch( err=>{
            this.$Message.error(err.msg)
        })        
    },
    // 获取微信配置
    getWxConf(){
        get_wxconf().then(res=>{
            console.log("微信配置res", res)
            this.forminfo = res.data
            console.log('this.formdata.is_formal',this.formdata.is_formal)
            //0  测试服    1正式服
            let newUrl = ''
            if(this.formdata.is_formal == 0){
                //测试服
                this.forminfo.url = 'cscontract.hulianzaowu.top' 
                newUrl = 'cscontract.hulianzaowu.top' 
            }else{
                // 正式服
                this.forminfo.url = this.forminfo.url ? this.forminfo.url : 'susuyunqian.hulianzaowu.com'      
                newUrl =  'susuyunqian.hulianzaowu.com'  
            }
            // if(this.forminfo.url == ''){
            //     this.forminfo.url = document.location.hostname //获取域名 
            // }
            // if(this.forminfo.request == ''){
                this.forminfo.request = 'https://' + newUrl
            // }
            // if(this.forminfo.wss == ''){
                this.forminfo.wss = 'wss://' + newUrl
            // }
            // if(this.forminfo.upload == ''){
                this.forminfo.upload = 'https://' + newUrl
            // }
            // if(this.forminfo.download == ''){
                this.forminfo.download = 'https://' + newUrl + ';https://oss-cn-hangzhou.aliyuncs.com'
            // }
            // if(this.forminfo.udp == ''){
                this.forminfo.udp = 'udp://' + newUrl
            // }
            // if(this.forminfo.tcp == ''){
                this.forminfo.tcp = 'tcp://' + newUrl
            // }            
            this.cate2 = res.data.cate2
            if (this.forminfo.first_cate) {
                let data = []
                this.forminfo.cate2.forEach(item => {
                    if (item.pid == this.forminfo.first_cate) {
                        data.push(item)
                    }
                });
                this.forminfo.cate2 = data
            }
            if(res.data.is_private == 1){
                this.privacyVal = '1'  //已有网关协议
            }else{
                this.privacyVal = '2' //尚无网关协议
            }
            // this.$Message.success(res.msg)
        }).catch(res=>{
            console.log("微信配置失败res", res)
            this.$Message.warning(res.msg)
        })       
    },
    // 隐私协议即使刷新
    getPrivacy(){
        get_wxconf().then(res=>{            
            if(res.data.is_private == 1){
                this.privacyVal = '1'  //已有网关协议
            }else{
                this.privacyVal = '2' //尚无网关协议
            }
            // this.$Message.success(res.msg)
        }).catch(res=>{
            console.log("微信配置失败res", res)
            this.$Message.warning(res.msg)
        })       
    },
    //判断是否为第一次提交
    isFirstSubmit(){
        console.log('是否是第一次上传代码:',this.formdata.is_first == 0 ?'是！':'否!')
        if(this.formdata.is_first == 0){
            this.showFitstPrivate = true
        } 
        // else{
        //     this.upload_wxcode()
        // }
    },
    //提交代码
    upload_wxcode(){
        upload_wxcode().then(res=>{
            this.$Message.success(res.msg)
            // if(this.formdata.is_first == 0){
            //     this.getlist() //更新 is_first
            // }        
            if(this.formdata.is_first == 0){
                this.showFitstPrivate = true
            }  
            this.get_wxapprovestatus() //获取审核状态
        }).catch( err=>{
            this.$Message.error(err.msg)
            this.get_wxapprovestatus() //获取审核状态
        })
    },
    //提交审核
    upload_approve(){
        upload_approve().then(res=>{
            this.$Message.success(res.msg)
            this.get_wxapprovestatus() //获取审核状态
        }).catch(err=>{
            this.$Message.error(err.msg)
            this.get_wxapprovestatus() //获取审核状态
        })
    },
    //发布
    releax_code(){
        releax_code().then(res=>{
            this.$Message.success(res.msg)
            this.get_wxapprovestatus() //获取审核状态
            this.getWxConf() //获取微信配置        
            this.get_reloadwxcate() //获取分类
            this.get_wxversionlist() //获取版本记录
            this.approve_record() //获取审核记录
        }).catch(err=>{
            this.$Message.error(err.msg)
            this.get_wxapprovestatus() //获取审核状态
        })
    },
    //设置配置
    set_wxconf(){
        if(this.forminfo.first_cate == null||this.forminfo.first_cate == ''||this.forminfo.first_cate == undefined) return  this.$Message.error('请填写分类一')
        if(this.forminfo.second_cate == null||this.forminfo.second_cate == ''||this.forminfo.second_cate == undefined) return  this.$Message.error('请填写分类二')
        this.forminfo.first_id =  this.forminfo.first_cate
        this.forminfo.second_id = this.forminfo.second_cate
        this.forminfo.cate1.forEach(item=>{
            if(item.id == this.forminfo.first_id){
                this.forminfo.first_class =  item.class
            }
        })
        this.forminfo.cate2.forEach(item=>{
            if(item.id == this.forminfo.second_id){
                this.forminfo.second_class =  item.class
            }
        })
        // first_class: '',//第一个分类name
        // second_class: '',//第二个分类name
        // first_id: '', //第一个分类ID
        // second_id: '', //第二个分类ID
        console.log('提交的formInfo',this.forminfo)
        set_wxconf( this.forminfo).then(res=>{
            this.$Message.success(res.msg)
            this.getWxConf()
        }).catch(res=>{
            this.$Message.warning(res.msg)
            this.showUpdatas = true
        })  
    },
    //获取分类
    get_reloadwxcate(){
        get_reloadwxcate().then(res=>{  
            // this.forminfo.first_cate = null
            // this.forminfo.second_cate = null
            console.log('获取分类',res)
            this.forminfo.cate1 = res.data.cate1
            this.cate2 = res.data.cate2
        }).catch(res=>{
            this.$Message.warning(res.msg)
        })  
    },
    //获取审核状态
    get_wxapprovestatus(){
        get_wxapprovestatus().then(res=>{
           console.log('获取审核状态',res)
            // this.$Message.success(res.msg)
            this.ApproveStatus.approve_status = res.data.approve_status
            if(res.data.approve_status == 1){
                this.ApproveStatus.reason = res.data.reason
            }
        }).catch(res=>{
            this.$Message.warning(res.msg)
        })  
    },  
    //获取版本记录
    get_wxversionlist(){
        get_wxversionlist().then(res=>{
           console.log('获取版本记录',res)
            // this.$Message.success(res.msg)
            this.now_banben = res.data.version
            if( res.data.list.length != 0){
                this.new_banben = res.data.list[0].user_version
            }else {
                this.new_banben = ''
            }            
            this.historyList = res.data.list    
        }).catch(res=>{
            this.$Message.warning(res.msg)
        })  
    },    
    go_record(){
        this.show_record = true
        this.approve_record()
    },
     //获取审核记录
    approve_record(){
        approve_record(this.tablea).then(res=>{
           console.log('获取审核记录',res)
           this.orderListArr = res.data.data
           this.tablea.total = res.data.total
            // this.$Message.success(res.msg)
        }).catch(res=>{
            this.$Message.warning(res.msg)
            this.showUpdatas = true
        })  
    },    
    //更新协议前选择有无 现网配置
    privacyBtn(){
        this.getPrivacy()
        this.showPrivate = true        
    },   
    //第一次更新协议
    fitstUploadPrivate(){
        upload_private({privacy_ver:this.privacyVal}).then(res=>{
            this.showFitstPrivate = false
            this.$Message.success(res.msg)            
            // this.upload_wxcode()//上传代码
            this.getlist()
        }).catch(err=>{
            this.$Message.error(err.msg)
        })        
    },
    //更新协议
    upload_private(){
        upload_private({privacy_ver:this.privacyVal}).then(res=>{
            this.$Message.success(res.msg)
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    } ,
    uploadApplets(){
        let _this = this
        this.$Modal.confirm({
            title: '提示',
            content: '是否上传小程序',
            onOk: () => {
            this.$Message.info({
                content: '正在获取登录二维码',
                duration: 0
            });
            appletsLogin({
                url:_this.formdata.url,
                appid:_this.formdata.appid,
                name:_this.formdata.name,
                authentication:_this.formdata.authentication,
                version:_this.latestVersion,
                desc:_this.formdata.desc,
                uniacid:_this.unId,
                type:1
            }).then(res=>{
                _this.show_applets = true;
                _this.applet_name= '请扫码登录小程序'
                _this.applets_login = res.img;
                _this.$Message.destroy();
                _this.applets_id = res.id;
                that.timer =  setInterval(() => {
                    appletsCheck({
                        id : _this.applets_id,
                        uniacid :_this.unId,
                        type:1
                    }).then(res=>{
                        if(res.code === 200){
                            if(res.status === 3){
                                clearInterval(that.timer)
                                _this.$Message.error(res.msg)
                                _this.show_applets = false;
                                _this.$Message.destroy()
                            }else if(res.status === 2){
                                _this.applet_name= '小程序预览二维码'
                                _this.applets_login = res.data;
                                _this.$Message.destroy()
                                _this.showMust=false
                                clearInterval(that.timer)
                                saveQrcode({qrcode:res.data}).then(res=>{
                                    _this.$Message.success(res.msg)
                                }).catch(res=>{
                                    _this.$Message.warning(res.msg)
                                })
                            }else if(res.status === 0){
                                _this.$Message.destroy()
                                _this.showMust=true
                            } else {
                                _this.$Message.destroy()
                                _this.$Message.loading({
                                    content: '正在上传...',
                                    duration: 0
                                });
                            }
                        }
                    }).catch(err=>{
                        _this.$Message.warning(err.msg)
                    })
                }, 10000)
            }).catch(err=>{
                _this.$Message.destroy()
                _this.$Message.warning(err.msg)
            })
            },
            onCancel: () => {
            }
        });
    },
    handleMessage(e){
        console.log(e)
        this.set_wxconf()
    },
    getVersion(){
        updateRecord().then(res=>{
            this.latestVersion = res.data.latest_version
            this.currentVersion = res.data.current_version
            this.historyList = res.data.list
        }).catch(res=>{
            this.$Message.error(res.msg)
        })
    },
    get_authstatus(){
        this.$store.commit('changePollingSpin', true)
        get_authstatus().then(res=>{
            this.showView = false
            console.log('授权状态',res)    
            this.getWxConf() //获取微信配置        
            this.get_reloadwxcate() //获取分类
            this.get_wxapprovestatus() //获取审核状态
            this.get_wxversionlist() //获取版本记录
            this.approve_record() //获取审核记录
       
        }).catch(err=>{
            console.log('授权状态失败',err)
            if(err.code == 400){
                this.getWxBindUrl()
            }
        })
    },
    getpaylist(){
        paylist().then(res=>{
            this.paylist = res.data   
            this.paylist.pay_type = res.data.pay_type + ''        
        }).catch(err=>{
            console.log(err.msg)
            // this.$Message.error(err.msg)
        })
    },
    getlist(){
        applets().then(res=>{
            this.formdata = res.data
            this.formdata.uploadBtn = this.formdata.appid != "" ? true : false;
            this.formdata.is_first = res.data.is_first
            this.qrcode = res.data.qr_code
            this.releaseApplet = res.data.is_release_applet
            this.forminfo.appid = this.formdata.appid
            this.forminfo.name = this.formdata.name
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    },
    goone(){
        if(this.formdata.appid=='') return this.$Message.warning('请填写APPID')
        if(this.formdata.appsecret=='') return this.$Message.warning('请填写APP Secret')
        save(this.formdata).then(res=>{
            this.$Message.success(res.msg)
            this.getlist()
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    },
    //iframe
    getWxBindUrl(){
        this.loading_status1 = true
        let  _this = this
        wxBindAuth().then(res=>{
            console.log('绑定授权',res)
            _this.auth_url = res.data.url
            let iframe = document.getElementById("wx_iframe");
            iframe.onload = function(){
                _this.loading_status1 = false
            }
            this.$store.commit('changePollingSpin', true)
        }).catch(err=>{
            this.$store.commit('changePollingSpin', true)
            this.loading_status1 = false
            console.log(err)
        })
    },

    changeTablea(v){
        this.tablea.page = v;
        this.approve_record();
    },
    getCaption(obj) {
        var index = obj.lastIndexOf("//");
        obj = obj.substring(index + 1, obj.length);
        return obj;
    },
    payBtn(){
        this.paylist.appid = this.formdata.appid
        this.paylist.appsecret = this.formdata.appsecret
        if(this.paylist.appid=='') return this.$Message.warning('请返回小程序配置填写APPID')
        if(this.paylist.appsecret=='') return this.$Message.warning('请返回小程序配置填写APP Secret')
        if(this.paylist.mchid=='' && this.paylist.pay_type == 1) return this.$Message.warning('请填写微信支付商户号')
        if(this.paylist.key=='' && this.paylist.pay_type == 1) return this.$Message.warning('请生成微信支付密钥')
        if(this.paylist.fubei_merchant_id=='' && this.paylist.pay_type == 2) return this.$Message.warning('请填写付呗商户号')
        if(this.paylist.fubei_store_id=='' && this.paylist.pay_type == 2) return this.$Message.warning('请填写付呗商户门店号')
        savepay(this.paylist).then(res=>{            
            localStorage.setItem("pay_type_h5", this.paylist.pay_type ? this.paylist.pay_type : 0 ) //是否展示h5支付 pay_type 为2不显示H5支付
            this.$Message.success(res.msg)
        }).catch(res=>{
            this.$Message.error(res.msg)
        })
    },
    
}
}
</script>

<style scoped lang="scss">
// /deep/.ivu-modal-content{
//   height: 600px;
//   overflow-y: auto;
// }
.contshow {
    display: none;
}
.genxin-img {
    margin-left: 20px;
    cursor: pointer;
}
.modal-heigh {
    height: 420px;
    width: 100%;
    overflow-y: auto;
}
::deep #update-history-list .ivu-modal-footer{
    border:0
}
#update-history-list .ivu-modal-footer{
    border:0
}
.model_bottom_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 20px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
    padding-top:50px
}
.footer-bottom {
    width: 100%;
    display: flex;
    position: relative;
    .footer-start{
        align-items: flex-start;
        justify-content: flex-start;
        margin-left:25px ;
    }
    .footer-end{
        position: absolute;
        right: 10px;
        align-items: flex-end;
        justify-content: flex-end;
    }
}
.user_page {
    .top_change {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        .l_where {
            font-size: 18px;
            font-weight: bold;
            color: #1B2742;
            span {
                color: #4877E8;
            }
        }
        .r_back {
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }
        .middle_width {
            width: 30%;
            margin: auto;
        }
        .nav_name {
            font-size: 20px;
            font-weight: bold;
            color: #545F78;
            height: 66px;
            line-height: 66px;
            cursor: pointer;
            position:relative;
            &:hover{
                color: #4877E8;
            }
        }
        .active {
            color: #4877E8;
            &:after{
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background: #4877E8;
                border-radius: 1px;
            }
        }
    }
    .card_bg {
        width: 100%;
        margin-top: 20px;
        border-radius: 8px;
        background: #fff;
        padding: 50px 60px;
        box-sizing: border-box;
        min-height: 75vh;
        position: relative;
        .xuxian {
            width: 100%;
            border-top:1px solid #e8eaec;
            position: absolute;
            bottom: 65px;
            margin-left: -60px ;
        }
        .s-b {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
        .input_nav {
            margin-bottom: 25px;
            .label {
                font-size: 14px;
                font-weight: bold;
                color: #545F78;
                text-align: right;
                width: 100px;
            }
            .long {
                width: 130px;
            }
            .val {
                width: 350px;
            }
        }
    }
    .top-top {
        display: flex;
    }
    .noapp {
        display: flex;       
        justify-content: flex-start;
        align-items: flex-start; 
    }
    .noapp-ewm{
        width: 240px;
        text-align: center;
    }
    .noapp-ewm img{
        width: 240px;
        height: 240px;
    }    
    .top-right {
        width: 25%;
        padding: 0;
        background: white;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 20px;
        margin-left: 20px ;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .top-rigth-cof {
            position:absolute;
            top:15px;
            right:15px;
            text-decoration:underline;
            cursor: pointer;            
        }
        .top-right-but {
            position:absolute;
            top:15px;
            right:15px;
            cursor: pointer;
            color: #4877E8;
            border: 1px solid #4877E8;
            padding: 5px 10px;
            border-radius: 5px;
        }
        .top-right-fabu {
            position:absolute;
            top:15px;
            right:15px;
            cursor: pointer;
            color: #4877E8;
            border: 1px solid #4877E8;
            padding: 5px 10px;
            border-radius: 5px;
        }
        .top-right-fabu:hover{
            opacity: 0.6;
        }
        .top-right-but:hover{
            opacity: 0.6;
        }
        span {
            position:absolute;
            top:15px;
            left:15px;            
            cursor: pointer;
            color: #545F78;
            font-family: PingFang SC;
            text-decoration: underline;
        }
        button {
            height: 100%;
            padding: 13px 50px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            line-height: 15px;
            background: linear-gradient(to right, #4877E8 0%, #759EFF 100%) !important;
        }
        img {
            width: 12px;
            height: 12px;        
            margin-right: 5px;
        }
        .in-examine {
            color: #4877E8;
            font-weight: 500;
            font-family: PingFang SC;
            font-size: 30px;
        }
        .fail-examine{
            color: #1B2742;
            font-weight: 500;
            font-family: PingFang SC;
            font-size: 30px;
            text-align: center;
        }
        .fail-reason{
            color: #545F78;
            font-family: PingFang SC;
            font-size: 15px;
        }
    }
    .top_number {
        width: 74%;
        padding: 40px 60px 20px 30px;
        box-sizing: border-box;
        background: white;
        border-radius: 10px;
        margin-top: 20px;
        display: flex;
        .sss {
            display: flex;
            align-items: center;
            margin-left: 30px;
            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 60px;
                background: #DBDEE6;
                margin-left: 20px;
            }
        }
        .wrap-item {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .num {
                font-size: 44px;
                font-weight: 400;
                color: #1B2742;
            }
            .word {
                font-size: 18px;
                font-weight: bold;
                color: #818899;
            }
            .btn-s {
                width: 80px;
                height: 30px;
                margin-top: 10px;
            }
            .btn {
                width: 80px;
                height: 30px;
                background: #FFFFFF;
                border: 1px solid #4877E8;
                border-radius: 6px;
                font-size: 14px;
                color: #4877E8;
                margin-top: 10px;
            }
        }
    }
    .history {
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        width: 100%;
        margin-top: 20px;
        .title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1B2742;
            display: flex;
            align-items: center;
            .iconfont {
                color: #4877E8;
                margin-right: 8px;
                font-size: 20px;
            }
        }
        .card_wrap {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #E4EAF9;
            border-radius: 7px;
            margin-top: 10px;
            padding: 16px;
            box-sizing: border-box;
            font-size: 16px;
        }
    }
}

</style>
